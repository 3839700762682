import { IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc } from '@tabler/icons';

const icons = {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc
};

const userMenu = {
    id: 'user',
    title: '',
    type: 'group',
    children: [
        {
            id: 'users',
            title: '회원',
            type: 'collapse',
            icon: icons.IconUserCheck,
            children: [
                {
                    id: 'user-default',
                    title: '일반 회원',
                    type: 'item',
                    url: '/user/default'
                }
            ]
        }
    ]
};

export default userMenu;
