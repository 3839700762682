import { IconSchool } from '@tabler/icons';

const icons = {
    IconSchool
};

const CourseRoute = {
    id: 'course-route',
    type: 'group',
    children: [
        {
            id: 'course',
            title: '클래스',
            type: 'collapse',
            icon: icons.IconSchool,
            children: [
                {
                    id: 'course-list',
                    title: '클래스 리스트',
                    type: 'item',
                    url: '/course/list'
                },
                {
                    id: 'course-add',
                    title: '클래스 추가',
                    type: 'item',
                    url: '/course/add/new'
                }
            ]
        }
    ]
};

export default CourseRoute;
