import React, { CSSProperties } from 'react';
import { userListProfile } from '../../_mockApis/user-profile/types';
import { Box, Chip, duration, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import { Theme, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { CategoryListType } from '../../types/content.types';
import { Draggable } from 'react-beautiful-dnd';
import { VodListTypes } from '../../types/video.types';
import { ConvertSecond } from '../../utils/convert.second';
import { timeSet } from '../../utils/time.set';

interface props {
    row: VodListTypes;
    index: number;
}

const VideoRow = ({ row, index }: props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const handlerGoToProfile = (idx: string) => {
        navigation(`/video/modify/${idx}`);
    };

    const [open, setOpen] = React.useState(false);
    return (
        <TableRow hover>
            <TableCell>
                <Grid container spacing={2} alignItems="center" style={{ backgroundColor: 'transparent' }}>
                    <Grid item xs zeroMinWidth style={{ backgroundColor: 'transparent' }}>
                        <Typography align="left" variant="subtitle1" component="div">
                            {row.title}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography align="left" variant="subtitle2" noWrap>
                    {ConvertSecond(row.duration)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography align="left" variant="subtitle2" noWrap>
                    {timeSet(row.createdAt, 1)}
                </Typography>
            </TableCell>
            <TableCell>
                {row.status === '1' && (
                    <Chip
                        label="진행중"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                            color: theme.palette.success.dark
                        }}
                    />
                )}
                {row.status === '2' && (
                    <Chip
                        label="완료"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                            color: theme.palette.orange.dark
                        }}
                    />
                )}
                {row.status === '3' && (
                    <Chip
                        label="에러"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                            color: theme.palette.orange.dark
                        }}
                    />
                )}
            </TableCell>
            <TableCell>
                {row.use === 1 && (
                    <Chip
                        label="사용"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                            color: theme.palette.success.dark
                        }}
                    />
                )}
                {row.use === 0 && (
                    <Chip
                        label="미사용"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                            color: theme.palette.orange.dark
                        }}
                    />
                )}
            </TableCell>
            <TableCell align="center" sx={{ pr: 3 }}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Tooltip placement="top" title="자세히">
                        <IconButton
                            onClick={() => handlerGoToProfile(row.id)}
                            color="primary"
                            sx={{
                                color: theme.palette.orange.dark,
                                borderColor: theme.palette.orange.main,
                                '&:hover ': { background: theme.palette.orange.light }
                            }}
                            size="large"
                        >
                            <MoreTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default VideoRow;
