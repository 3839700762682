import { IconMovie } from '@tabler/icons';

const icons = {
    IconMovie
};

const VideoRoute = {
    id: 'video-route',
    type: 'group',
    children: [
        {
            id: 'video',
            title: 'VOD',
            type: 'collapse',
            icon: icons.IconMovie,
            children: [
                {
                    id: 'video-list',
                    title: 'VOD 리스트',
                    type: 'item',
                    url: '/video/list'
                },
                {
                    id: 'video-add',
                    title: 'VOD 업로드',
                    type: 'item',
                    url: '/video/add/new'
                }
            ]
        }
    ]
};

export default VideoRoute;
