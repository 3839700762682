// material-ui
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    TextField
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';

// types
interface Props {
    title: string;
    content: string;
    open: boolean;
    handleClose: Dispatch<SetStateAction<Boolean>>;
    handleSuccess: (text, time, id) => void;
    hideContent?: boolean;
    tempContent: { title: string; time: number; id?: string };
}
const validationSchema = yup.object({
    title: yup.string().min(2, '제목을 2글자 이상 입력해주세요').required('제목을 입력해주세요'),
    time: yup.number().positive().required('시간을 입력해주세요').min(0)
});

export default function AlertInputDialog({ title, content, open, handleClose, handleSuccess, hideContent, tempContent }: Props) {
    const formik = useFormik({
        initialValues: {
            title: '',
            time: 0
        },
        validationSchema,
        onSubmit: async (values) => {
            handleSuccess(values.title, values.time, tempContent.id);
        }
    });
    useEffect(() => {
        handleChangeTempContent();
    }, [open]);
    const handleChangeTempContent = () => {
        formik.setFieldValue('title', tempContent.title);
        formik.setFieldValue('time', tempContent.time);
    };
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            fullWidth
            maxWidth="xs"
            aria-labelledby="item-delete-title"
            aria-describedby="item-delete-description"
        >
            <DialogTitle id="item-delete-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="item-delete-description">{content}</DialogContentText>
                <FormControl sx={{ margin: '10px 0' }}>
                    <TextField
                        autoFocus
                        id="title"
                        fullWidth
                        label="제목"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />
                </FormControl>
                <FormControl sx={{ margin: '10px 0' }}>
                    <TextField
                        type={'number'}
                        autoFocus
                        id="time"
                        fullWidth
                        label="시간"
                        value={formik.values.time}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.time && Boolean(formik.errors.time)}
                        helperText={formik.touched.time && formik.errors.time}
                    />
                </FormControl>
            </DialogContent>
            {!hideContent && (
                <DialogActions sx={{ mr: 2 }}>
                    <Button onClick={() => handleClose(false)} color="error">
                        Cancel
                    </Button>
                    <Button variant="contained" size="small" onClick={() => formik.handleSubmit()}>
                        OK
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}
