export const VodStatusHelper = (type: string) => {
    switch (type) {
        case '1':
            return '진행중';
        case '2':
            return '완료';
        case 'none':
            return '전체';
        default:
            return '-';
    }
};

export const VodSortOptionHelper = (type: string) => {
    switch (type) {
        case 'createdAt':
            return '등록순';
        case 'updatedAt':
            return '업데이트순';
        case 'none':
            return '-';
        default:
            return '-';
    }
};

export const SortDESCASCHelper = (type: string) => {
    switch (type) {
        case 'ASC':
            return '오름차순';
        case 'DESC':
            return '내림차순';
        default:
            return '-';
    }
};

export const SortUseVodHelper = (type: string) => {
    switch (type) {
        case '1':
            return '사용';
        case '0':
            return '미사용';
        case 'none':
            return '전체';
        default:
            return '-';
    }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const VodMenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150
        }
    }
};
export const VodSortOptionSelectConst = [
    { value: 'createdAt', label: '등록순' },
    { value: 'updatedAt', label: '업데이트순' }
];
export const ListSelectSort = [
    { value: 'DESC', label: '내림차순' },
    { value: 'ASC', label: '오름순' }
];
export const VodStatusSelectConst = [
    { value: 'none', label: '전체' },
    { value: '1', label: '진행중' },
    { value: '2', label: '완료' },
    { value: '3', label: '오류' }
];
export const VodUseSelectConst = [
    { value: 'none', label: '전체' },
    { value: '0', label: '미사용' },
    { value: '1', label: '사용중' }
];
