import { format, isValid } from 'date-fns';

export const timeSet = (time, formatType) => {
    const formatting = formatType === 1 ? 'dd MM yyyy' : 'dd MM yyyy';
    if (!time) {
        return '';
    }
    const date = new Date(time);
    const valid = isValid(date);
    if (!valid) {
        return '';
    }
    return format(date, formatting);
};
