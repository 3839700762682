import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosServices from '../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import { PageWrapper } from '../../layout/wrapper/page.wrapper';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { gridSpacing } from '../../store/constant';
import { Button, FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import ReactAudioPlayer from 'react-audio-player';
import { useFileUpload } from 'use-file-upload';
import { byteCalc } from '../../utils/byte.calc';
import AdminVideo from '../../components/video/video.common';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import { ConvertSecond } from '../../utils/convert.second';
import videojs from 'video.js';

const validationSchema = yup.object({
    title: yup.string().min(2, '제목을 2글자 이상 입력해주세요').required('제목을 입력해주세요')
});

export const VideoAdd = () => {
    const navigate = useNavigate();
    const { axiosService } = useAxiosServices();
    const dispatch = useDispatch();
    const { idx } = useParams();

    const [alertOpen, setAlertOpen] = useState(false);
    // Content Value
    const [duration, setDuration] = useState(0);
    const [files, selectFiles] = useFileUpload();
    const [byte, setByte] = useState(0);
    //  Content Func
    const formik = useFormik({
        initialValues: {
            title: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            setAlertOpen(true);
        }
    });

    const handleFileSource = (file: any) => {
        if (file && file[0]) {
            return file[0].source;
        } else {
            return file?.source;
        }
    };
    const handleFileType = (file: any) => {
        if (file && file[0]) {
            return file[0].file.type;
        } else {
            return file?.file.type;
        }
    };
    const handleFilName = (file: any) => {
        if (file && file[0]) {
            return file[0].name;
        } else {
            return file?.name;
        }
    };
    const handleFile = (file: any) => {
        if (file && file[0]) {
            return file[0].file;
        } else {
            return file?.file;
        }
    };

    const [modify, setModify] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [progress, setProgress] = useState(0);

    const musicUpload = async () => {
        const { title } = formik.values;
        if (!modify) {
            if (!files) {
                // eslint-disable-next-line no-alert
                alert('VOD를 업로드해주세요.');
                return;
            }
        }
        try {
            setAlertOpen(false);
            setLoadingOpen(true);
            const form = new FormData();
            form.append('title', title);
            form.append('duration', `${Math.round(duration)}`);
            files && form.append('mainFile', handleFile(files));
            form.append('byte', `${byte}`);
            if (modify) {
                form.append('idx', idx);
            }
            const api = await axiosService.post(modify ? 'videos/update' : '/videos/add', form, {
                onUploadProgress: (evt) => {
                    console.log('EVENT', evt);
                    setProgress(Math.round((evt.loaded / evt.total) * 100));
                },

                headers: { 'Content-type': 'multipart/form-data' }
            });
            const { result } = api.data;
            if (result) {
                toast.info(modify ? 'VOD를 업데이트했습니다.' : 'VOD를 업로드했습니다.', { ...ToastSetting.default });
                if (modify) {
                } else {
                    navigate('/video/list');
                }
            } else {
                toast.info('Error', { ...ToastSetting.default });
            }
        } catch (e) {
            console.log('e', e);
        } finally {
            setLoadingOpen(false);
        }
    };
    useEffect(() => {
        if (idx && idx !== 'new') {
            getData();
        }
    }, [idx]);
    const getData = async () => {
        const api = await axiosService.post('/videos/find/admin', { id: idx });
        const { result, info, token, videoSource } = api.data;
        if (result) {
            console.log('API :', api.data);
            setModify(true);
            const drmSrc = {
                src: videoSource,
                type: 'application/dash+xml',
                keySystems: {}
            };
            if (token) {
                // @ts-ignore
                drmSrc.keySystems['com.widevine.alpha'] = {
                    url: 'https://license.pallycon.com/ri/widevine/licenseManager.do',
                    licenseHeaders: {
                        'pallycon-customdata-v2': token
                    }
                };
            }
            console.log('drm', drmSrc);
            setSrc(drmSrc);
        }
    };

    const insertFetch = async () => {
        setAlertOpen(false);
        await musicUpload();
    };

    const handleDuration = (d) => {
        console.log('d', d);
        setDuration(d);
    };
    const [src, setSrc] = useState<null | videojs.Tech.SourceObject>(null);

    const MemoVideo = useMemo(() => <AdminVideo source={src} handleUpdate={handleDuration} />, [files, src]);
    return (
        <>
            <PageWrapper title={'비디오 업로드'} subject={'비디오 업로드 및 관리가 가능합니다.'}>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container p={2} py={4} spacing={gridSpacing}>
                            <Grid item xs={12} md={8} container spacing={gridSpacing}>
                                {files && src && MemoVideo}
                            </Grid>
                            <Grid item xs={12} md={4} container spacing={gridSpacing}>
                                <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                                    <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 ' }}>
                                            <Button
                                                variant={'outlined'}
                                                onClick={() =>
                                                    selectFiles({ accept: 'video/*', multiple: false }, (te: any) => {
                                                        const { name, size, source, file } = te;
                                                        setByte(size);
                                                        setSrc({
                                                            ...{
                                                                src: handleFileSource(te),
                                                                type: handleFileType(te)
                                                            }
                                                        });
                                                        console.log('Files Selected', { name, size, source, file });
                                                    })
                                                }
                                            >
                                                {`VOD ${!files ? '선택' : `재선택 `}`}
                                            </Button>
                                        </div>
                                    </Grid>
                                    {files && (
                                        <Grid
                                            spacing={2}
                                            item
                                            xs={12}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            flexDirection={'column'}
                                        >
                                            <Typography variant={'h5'} sx={{ margin: '10px 0' }}>
                                                {handleFilName(files)}
                                            </Typography>
                                            <Typography variant={'h5'}>{byteCalc(byte)}</Typography>
                                            <Typography variant={'h5'}>길이 {ConvertSecond(duration)}</Typography>
                                        </Grid>
                                    )}

                                    <FormControl sx={{ margin: '10px 0' }}>
                                        <TextField
                                            id="title"
                                            fullWidth
                                            label="제목"
                                            value={formik.values.title}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.title && Boolean(formik.errors.title)}
                                            helperText={formik.touched.title && formik.errors.title}
                                        />
                                        <FormHelperText>식별용 이름으로 노출되지않습니다.</FormHelperText>
                                    </FormControl>
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 ' }}>
                                        <Button variant={'outlined'} type={'submit'}>
                                            {`업로드`}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </PageWrapper>
            <AlertDialog
                title={'업로드 중'}
                content={`${progress}%`}
                open={loadingOpen}
                handleClose={setLoadingOpen}
                handleSuccess={() => null}
                hideContent
            />
            <AlertDialog
                title={modify ? 'VOD 업데이트' : 'VOD 업로드'}
                content={modify ? 'VOD를 업데이트하시겠습니까?' : `VOD를 업로드하시겠습니까?`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => insertFetch()}
            />
        </>
    );
};
