import other from './other';
import { NavItemType } from 'types';
import dashboard from './dashboard';
import userMenu from './userList';
import ContentRoute from './content.route';
import VideoRoute from './video.route';

import ChapterRoute from './chapter.route';
import CourseRoute from './course.route';
import orderRouting from './order.route';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, userMenu, ContentRoute, VideoRoute, ChapterRoute, CourseRoute, orderRouting]
};

export default menuItems;
