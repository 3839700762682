import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosServices from '../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import { PageWrapper } from '../../layout/wrapper/page.wrapper';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { gridSpacing } from '../../store/constant';
import { Button, FormControl, FormHelperText, Grid, Stack, TextField, Typography } from '@mui/material';
import { useFileUpload } from 'use-file-upload';
import { byteCalc } from '../../utils/byte.calc';
import AdminVideo from '../../components/video/video.common';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import { ConvertSecond } from '../../utils/convert.second';
import videojs, { VideoJsPlayer } from 'video.js';
import { ISideContentType, VodListTypes } from '../../types/video.types';
import VideoDrawer from '../video/video.drawer';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { IconMovie } from '@tabler/icons';
import { ChapterSide } from './chapter.side';
import { getUuid } from '../../utils/get.uuid';
import AlertInputDialog from '../../ui-component/dialog/AlertInputDialog';
import { NumberFixed } from '../../utils/number.fixed';
import ReactDraftWysiwyg from '../../ui-component/editor/ReactDraftWysiwyg';
import { useTheme } from '@mui/material/styles';
import { useDebounce } from '../../utils/use.debounce';
import { useInView } from 'react-intersection-observer';
import useSticky from '../../utils/use.sticky';
import { HtmlEditor } from '../../components/editor/html.editor';

const validationSchema = yup.object({
    title: yup.string().min(2, '제목을 2글자 이상 입력해주세요').required('제목을 입력해주세요')
});

export const ChapterAdd = () => {
    const navigate = useNavigate();
    const { axiosService } = useAxiosServices();
    const dispatch = useDispatch();
    const [alertOpen, setAlertOpen] = useState(false);
    const [checkOpen, setCheckOpen] = useState(false);
    const { idx } = useParams();
    const [videoId, setVideoId] = useState<string | null>(null);
    // Content Value
    const [duration, setDuration] = useState(0);
    const [time, setTime] = useState(0);
    const [files, selectFiles] = useFileUpload();
    const [byte, setByte] = useState(0);
    const [video, setVideo] = useState<VodListTypes | null>(null);
    //  Content Func
    const formik = useFormik({
        initialValues: {
            title: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            setAlertOpen(true);
        }
    });

    const [modify, setModify] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const theme = useTheme();

    const getChapterDataApi = async () => {
        const api = await axiosService.post('/chapter/info/admin', { id: idx });
        const { result, chapterInfo, videoInfo, sideList } = api.data;
        if (result) {
            setModify(true);
            setContentList([...sideList]);
            await getDataVideo(videoInfo.id);
            await formik.setFieldValue('title', chapterInfo.title);
            if (sideList && sideList.length > 0) {
                const addData = sideList[0];
                setTempContent({ ...addData });
                setContentValue(addData?.content ?? '');
            }
        }
    };

    const chapterUpload = async () => {
        const { title } = formik.values;
        try {
            setAlertOpen(false);
            const data = modify
                ? { id: idx, title, videoId: video.id, sideList: contentList }
                : {
                      title,
                      videoId: video.id,
                      sideList: contentList
                  };
            const api = await axiosService.post(modify ? 'chapter/update/admin' : 'chapter/add', data);
            const { result } = api.data;
            if (result) {
                const resultText = modify ? '챕터를 업데이트했습니다.' : '챕터를 추가했습니다.';
                toast.info(resultText, { ...ToastSetting.default });
                navigate('/chapter/list');
            } else {
                toast.info('Error', { ...ToastSetting.default });
            }
        } catch (e) {
            console.log('e', e);
        } finally {
            setLoadingOpen(false);
        }
    };
    useEffect(() => {
        if (idx && idx !== 'new') {
            getChapterDataApi();
        }
    }, []);
    const getDataVideo = async (id) => {
        try {
            if (!id) {
                return;
            }
            const api = await axiosService.post('/videos/find/admin', { id });
            const { result, info, token, videoSource } = api.data;
            if (result) {
                console.log('API :', api.data);
                setVideoId(id);
                setVideo({ ...info });
                setDuration(info.duration);
                setByte(info.byte);

                const drmSrc = {
                    src: videoSource,
                    type: 'application/dash+xml'
                };
                if (token) {
                    // @ts-ignore
                    drmSrc.keySystems = {};
                    // @ts-ignore
                    drmSrc.keySystems['com.widevine.alpha'] = {
                        url: 'https://license.pallycon.com/ri/widevine/licenseManager.do',
                        licenseHeaders: {
                            'pallycon-customdata-v2': token
                        }
                    };
                }
                console.log('drm', drmSrc);
                setSrc(drmSrc);
            }
        } catch (e) {
        } finally {
            setCheckOpen(false);
        }
    };

    const insertFetch = async () => {
        setAlertOpen(false);
        await chapterUpload();
    };

    const handleDuration = (d) => {
        setDuration(d);
    };
    const [src, setSrc] = useState<null | videojs.Tech.SourceObject>(null);

    const [deleteOpen, setDeleteOpen] = useState(false);
    const deleteVod = async () => {
        const api = await axiosService.post('/chapter/delete/admin', { id: idx });
        const { result } = api.data;
        if (result) {
            toast.info('챕터를 삭제했습니다.', { ...ToastSetting.default });
            navigate('/chapter/list');
        }
    };

    // Drawer
    const [openDrawer, setOpenDrawer] = useState(false);
    const [tempId, setTempId] = useState<null | string>(null);
    const handleAddVideo = (rows: VodListTypes) => {
        setTempId(rows.id);
        videoId ? setCheckOpen(true) : getDataVideo(rows.id);
        setOpenDrawer(false);
    };

    const [contentList, setContentList] = useState<ISideContentType[]>([]);
    const [tempContent, setTempContent] = useState<ISideContentType>({ title: '', time: 0, id: null, content: '-' });

    const [addOpen, setAddOpen] = useState(false);
    const [contentDeleteOpen, setContentDeleteIOpen] = useState(false);
    const handleContentList = (changeList) => {
        setContentList([...changeList]);
    };
    const handleReadyContentList = () => {
        const getTime = getPlayerTime();
        const addContent = { title: '', time: getTime, id: null, content: '-' };
        setTempContent({ ...addContent });
        setAddOpen(true);
    };
    const handleModifyContentList = (content: ISideContentType) => {
        setTempContent({ ...content });
        setAddOpen(true);
    };
    const handleAddContentList = (title, inputTime, id) => {
        setAddOpen(false);
        if (!id) {
            const temp = { title, time: inputTime, id: getUuid(), content: '-' };
            setContentList((p) => [...p, { ...temp }]);
            handleContentSelect(temp);
        } else {
            const mapList = contentList.map((v) => {
                if (v.id === id) {
                    handleContentSelect(v);
                    return { title, time: inputTime, id, content: v.content };
                }
                return v;
            });
            setContentList([...mapList]);
        }
    };

    const handleFilterContentList = (content: ISideContentType) => {
        setTempContent({ ...content });
        setContentDeleteIOpen(true);
        // const filterContent = contentList.filter((v) => v.id !== content.id);
        // setContentList([...filterContent]);
    };
    const handleFilterContentListFinish = () => {
        setContentDeleteIOpen(false);
        const filterContent = contentList.filter((v) => v.id !== tempContent.id);
        setContentList([...filterContent]);
    };

    const getPlayerTime = () => {
        const getTime = player.current?.currentTime() ?? 0;
        console.log(getTime);
        return NumberFixed(getTime);
    };
    const handleContentSelect = (content: ISideContentType) => {
        setTempContent({ ...content });
        const select = content.time;
        if (player.current) {
            player.current?.currentTime(select);
            player.current?.pause();
            setContentValue(content.content);
        }
    };
    const player = useRef<VideoJsPlayer | null>(null);
    const MemoVideo = useMemo(() => <AdminVideo source={src} handleUpdate={handleDuration} playerControlRef={player} />, [src]);
    const handleContentValue = () => {
        const id = contentList.find((v) => v.id === tempContent.id)?.id ?? null;
        if (id) {
            const mapList = contentList.map((v) => {
                if (v.id === id) {
                    return { ...v, content: contentValue };
                }
                return v;
            });
            setContentList([...mapList]);
        }
    };
    const [contentValue, setContentValue] = useState('');
    useDebounce(handleContentValue, 500, [contentValue]);
    const Wys = useMemo(
        () => (
            <Grid item xs={12}>
                <Stack spacing={gridSpacing}>
                    <HtmlEditor text={contentValue} setText={(t) => setContentValue(t)} useImagePlugin />
                </Stack>
            </Grid>
        ),
        [contentValue]
    );
    const { sticky, stickyRef } = useSticky();
    const disabledHandler = () => {
        if (contentList.length === 0) {
            return true;
        }
        if (!video) {
            return true;
        }
        return false;
    };

    console.log('SIDELIST', contentList);
    return (
        <>
            <PageWrapper
                title={'챕터 관리'}
                subject={'챕터 수정 및 관리가 가능합니다.'}
                sideContent={
                    <Grid>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 ' }}>
                            <Button variant={'outlined'} type={'submit'} disabled={disabledHandler()} onClick={() => formik.handleSubmit()}>
                                {modify ? `업데이트` : `업로드`}
                            </Button>
                        </div>
                        {modify && (
                            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                                <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 ' }}>
                                    <Button onClick={() => setDeleteOpen(true)} variant={'outlined'}>{`챕터 삭제`}</Button>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                }
            >
                <div style={{ minHeight: '100vh' }}>
                    <form>
                        <Grid container p={2} py={4} spacing={gridSpacing}>
                            <Grid item xs={12} md={8} container spacing={gridSpacing}>
                                <Grid container xs={12} m={2} alignItems={'center'} sx={{ maxHeight: '50px' }}>
                                    <Grid xs={2}>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            fullWidth
                                            size="small"
                                            sx={{ minWidth: 80 }}
                                            onClick={() => setOpenDrawer(true)}
                                            startIcon={<IconMovie />}
                                        >
                                            {videoId ? '교체' : '불러오기'}
                                        </Button>
                                    </Grid>
                                    {video && (
                                        <Grid pl={2} spacing={2} item xs={10} alignItems={'center'} justifyContent={'center'}>
                                            {`${video.title} | ${byteCalc(video.byte)} | 길이 ${ConvertSecond(video.duration)}`}
                                        </Grid>
                                    )}
                                </Grid>
                                {src && MemoVideo}
                                <FormControl sx={{ margin: '15px', marginTop: '20px' }} fullWidth>
                                    <TextField
                                        id="title"
                                        fullWidth
                                        label="제목"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.title && Boolean(formik.errors.title)}
                                        helperText={formik.touched.title && formik.errors.title}
                                    />
                                </FormControl>
                                {contentList.length > 0 && Wys}
                            </Grid>
                            <Grid item xs={12} md={4} container spacing={gridSpacing}>
                                <Grid
                                    ref={stickyRef}
                                    spacing={2}
                                    item
                                    xs={12}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    sx={{
                                        position: { xs: 'relative', md: sticky ? 'fixed' : 'relative' },
                                        width: { xs: '100%', md: 350 },
                                        top: sticky ? '100px' : 0
                                    }}
                                >
                                    <ChapterSide
                                        video={video}
                                        handleReadyContentList={handleReadyContentList}
                                        contentList={contentList}
                                        handleContentList={handleContentList}
                                        handleModifyContentList={handleModifyContentList}
                                        handleFilterContentList={handleFilterContentList}
                                        handleContentSelect={handleContentSelect}
                                    />
                                    {/* <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 ' }}> */}
                                    {/*    <Button variant={'outlined'} type={'submit'}> */}
                                    {/*        {`업데이트`} */}
                                    {/*    </Button> */}
                                    {/* </div> */}
                                    {/* <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}> */}
                                    {/*    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 ' }}> */}
                                    {/*        <Button onClick={() => setDeleteOpen(true)} variant={'outlined'}>{`VOD 삭제`}</Button> */}
                                    {/*    </div> */}
                                    {/* </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </PageWrapper>
            <AlertDialog
                title={'VOD 불러오기'}
                content={`이미 작업중인 VOD가 있습니다. 교체하시겠습니까? `}
                open={checkOpen}
                handleClose={setCheckOpen}
                handleSuccess={() => getDataVideo(tempId)}
            />
            <AlertDialog
                title={modify ? '챕터 업데이트' : '챕터 업로드'}
                content={modify ? '챕터를 업데이트하시겠습니까?' : `챕터를 업로드하시겠습니까?`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => insertFetch()}
            />
            <AlertDialog
                title={'챕터 삭제'}
                content={'챕터를 삭제하시겠습니까?'}
                open={deleteOpen}
                handleClose={setDeleteOpen}
                handleSuccess={() => deleteVod()}
            />
            <AlertDialog
                title={'콘텐츠 삭제'}
                content={'콘텐츠를 삭제하시겠습니까?'}
                open={contentDeleteOpen}
                handleClose={setContentDeleteIOpen}
                handleSuccess={() => handleFilterContentListFinish()}
            />
            <AlertInputDialog
                tempContent={tempContent}
                title={tempContent?.id ? '콘텐츠 수정' : '콘텐츠 추가'}
                content={``}
                open={addOpen}
                handleClose={setAddOpen}
                handleSuccess={handleAddContentList}
            />
            <VideoDrawer
                currentId={videoId}
                open={openDrawer}
                handleDrawerOpen={() => setOpenDrawer(false)}
                handleAddVideo={handleAddVideo}
            />
        </>
    );
};
