import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Grid, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';

interface IPageWrapper {
    children: React.ReactNode;
    title: string;
    subject?: string;
    sideContent?: any;
}

export const PageWrapper = ({ children, subject, title, sideContent }: IPageWrapper) => (
    <MainCard
        title={
            <Grid container alignItems="space-between" justifyContent="center" flexDirection={'column'} spacing={gridSpacing}>
                <Grid item container alignItems="center" justifyContent="space-between">
                    <Typography variant="h3">{title}</Typography>
                    {sideContent}
                </Grid>
                {subject && (
                    <Grid item>
                        <Typography variant="h5">{`* ${subject}`}</Typography>
                    </Grid>
                )}
            </Grid>
        }
        content={false}
    >
        {children}
    </MainCard>
);
