import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosServices from '../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import { PageWrapper } from '../../layout/wrapper/page.wrapper';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { gridSpacing } from '../../store/constant';
import { Button, FormControl, FormHelperText, Grid, Stack, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import { IChapterContentType, ISideContentType, VodListTypes } from '../../types/video.types';

import { useTheme } from '@mui/material/styles';
import useSticky from 'utils/use.sticky';
import { HtmlEditor } from 'components/editor/html.editor';
import ChapterDrawer from '../chapter/chapter.drawer';
import { CourseSide } from './course.side';
import { CategoryListType } from '../../types/content.types';
import { useFileUpload } from 'use-file-upload';
import ImageUploader from '../../ui-component/uploader/imageUploader';

const validationSchema = yup.object({
    title: yup.string().min(2, '제목을 2글자 이상 입력해주세요').required('제목을 입력해주세요'),
    amount: yup.number().positive().required('가격을 입력해주세요').min(0)
});

export const CourseAdd = () => {
    const navigate = useNavigate();
    const { axiosService } = useAxiosServices();
    const dispatch = useDispatch();
    const [alertOpen, setAlertOpen] = useState(false);
    const [checkOpen, setCheckOpen] = useState(false);
    const { idx } = useParams();
    // Content Value
    const [contentList, setContentList] = useState<IChapterContentType[]>([]);
    //  Content Func
    const formik = useFormik({
        initialValues: {
            title: '',
            amount: 0
        },
        validationSchema,
        onSubmit: async (values) => {
            setAlertOpen(true);
        }
    });

    const [modify, setModify] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const theme = useTheme();

    const getChapterDataApi = async () => {
        const api = await axiosService.post('/chapter/info/admin', { id: idx });
        const { result, chapterInfo, videoInfo, sideList } = api.data;
        if (result) {
        }
    };

    const chapterUpload = async () => {};
    useEffect(() => {}, []);

    const insertFetch = async () => {
        setAlertOpen(false);
        await chapterUpload();
    };

    const [deleteOpen, setDeleteOpen] = useState(false);
    const deleteVod = async () => {
        const api = await axiosService.post('/chapter/delete/admin', { id: idx });
        const { result } = api.data;
        if (result) {
            toast.info('챕터를 삭제했습니다.', { ...ToastSetting.default });
            navigate('/chapter/list');
        }
    };

    // Drawer
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleAddVideo = (rows: IChapterContentType) => {
        const find = contentList.find((v) => v.id === rows.id);
        if (find) {
            setCheckOpen(true);
        } else {
            setContentList((v) => [...v, rows]);
        }
        setOpenDrawer(false);
    };

    const [tempContent, setTempContent] = useState<ISideContentType>({ title: '', time: 0, id: null, content: '-' });

    const [contentDeleteOpen, setContentDeleteIOpen] = useState(false);
    const handleContentList = (changeList) => {
        setContentList([...changeList]);
    };
    const handleReadyContentList = () => {
        setOpenDrawer(true);
    };

    const handleFilterContentList = (content: IChapterContentType) => {
        setTempContent({ ...content });
        setContentDeleteIOpen(true);
        // const filterContent = contentList.filter((v) => v.id !== content.id);
        // setContentList([...filterContent]);
    };
    const handleFilterContentListFinish = () => {
        setContentDeleteIOpen(false);
        const filterContent = contentList.filter((v) => v.id !== tempContent.id);
        setContentList([...filterContent]);
    };

    const [contentValue, setContentValue] = useState('');
    const Wys = useMemo(
        () => (
            <Grid item xs={12}>
                <Stack spacing={gridSpacing}>
                    <HtmlEditor text={contentValue} setText={(t) => setContentValue(t)} useImagePlugin />
                </Stack>
            </Grid>
        ),
        [contentValue]
    );
    const { sticky, stickyRef } = useSticky();
    const disabledHandler = () => {
        if (contentList.length === 0) {
            return true;
        }
        return false;
    };

    console.log('SIDELIST', contentList);
    const [category, setCategory] = useState<CategoryListType[]>([]);
    const getDataCategory = async () => {
        const response = await axiosService.post('/content/category/list');
        const { result, list } = response.data;
        if (result) {
            setCategory([...list]);
        }
    };
    const [files, selectFiles] = useFileUpload();
    const fileSource = (file: any) => {
        if (file && file[0]) {
            return file[0].source;
        } else {
            return file?.source;
        }
    };
    const fileSourceBolb = (file: any) => {
        if (file && file[0]) {
            return file[0].file;
        } else {
            return file.file;
        }
    };
    const [mainImage, setMainImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const onCancel = () => {
        setMainImage(null);
    };
    const imageHandler = (file: any) => {
        setMainImage(file[0]);
    };
    return (
        <>
            <PageWrapper
                title={'클래스 관리'}
                subject={'클래스 수정 및 관리가 가능합니다.'}
                sideContent={
                    <Grid>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 ' }}>
                            <Button variant={'outlined'} type={'submit'} disabled={disabledHandler()} onClick={() => formik.handleSubmit()}>
                                {modify ? `업데이트` : `업로드`}
                            </Button>
                        </div>
                        {modify && (
                            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                                <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 ' }}>
                                    <Button onClick={() => setDeleteOpen(true)} variant={'outlined'}>{`클래스 삭제`}</Button>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                }
            >
                <div style={{ minHeight: '100vh' }}>
                    <form>
                        <Grid container p={2} py={4} spacing={gridSpacing}>
                            <Grid item xs={12} md={8} container spacing={gridSpacing}>
                                <FormControl sx={{ margin: '15px', marginTop: '20px' }} fullWidth>
                                    <TextField
                                        id="title"
                                        fullWidth
                                        label="제목"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.title && Boolean(formik.errors.title)}
                                        helperText={formik.touched.title && formik.errors.title}
                                    />
                                </FormControl>
                                <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant={'h4'}>클래스 이미지</Typography>
                                    <Typography variant={'h5'}>500 * 500 </Typography>
                                    <ImageUploader
                                        onChange={imageHandler}
                                        imageURL={imageURL}
                                        onCancel={onCancel}
                                        width={250}
                                        height={250}
                                    />
                                </Grid>
                                <FormControl sx={{ margin: '15px', marginTop: '20px' }} fullWidth>
                                    <TextField
                                        id="amount"
                                        fullWidth
                                        label="가격"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                                        helperText={formik.touched.amount && formik.errors.amount}
                                    />
                                </FormControl>
                                {Wys}
                            </Grid>
                            <Grid item xs={12} md={4} container spacing={gridSpacing}>
                                <Grid
                                    ref={stickyRef}
                                    spacing={2}
                                    item
                                    xs={12}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    sx={{
                                        position: { xs: 'relative', md: sticky ? 'fixed' : 'relative' },
                                        width: { xs: '100%', md: 350 },
                                        top: sticky ? '100px' : 0
                                    }}
                                >
                                    <CourseSide
                                        handleReadyContentList={handleReadyContentList}
                                        contentList={contentList}
                                        handleContentList={handleContentList}
                                        handleFilterContentList={handleFilterContentList}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </PageWrapper>

            <AlertDialog
                title={modify ? '챕터 업데이트' : '챕터 업로드'}
                content={modify ? '챕터를 업데이트하시겠습니까?' : `챕터를 업로드하시겠습니까?`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => insertFetch()}
            />
            <AlertDialog
                title={'챕터 삭제'}
                content={'챕터를 삭제하시겠습니까?'}
                open={deleteOpen}
                handleClose={setDeleteOpen}
                handleSuccess={() => deleteVod()}
            />
            <AlertDialog
                title={'콘텐츠 삭제'}
                content={'콘텐츠를 삭제하시겠습니까?'}
                open={contentDeleteOpen}
                handleClose={setContentDeleteIOpen}
                handleSuccess={() => handleFilterContentListFinish()}
            />
            <ChapterDrawer
                contentList={contentList}
                open={openDrawer}
                handleDrawerOpen={() => setOpenDrawer(false)}
                handleAddVideo={handleAddVideo}
            />
        </>
    );
};
