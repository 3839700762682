import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import DepotListWrapper from '../views/userPage/DepotListWrapper';
import CreateDepot from '../views/depot/create.depot';
import CharityPageWrapper from '../views/charity/charity.pageWrapper';
import CharityAdd from '../views/charity/charity.add';
import CharityUpdate from '../views/charity/charity.update';
import QrPage from '../views/settings/QrPage';
import TransactionWrapper from '../views/transaction/transactionWrapper';
import CommonSetting from '../views/settings/CommonSetting';
import SuburbSettingList from '../views/settings/suburb.setting';
import SuburbDetail from '../views/settings/suburb.detail';
import QrList from '../views/settings/QrList';
import DriverListWrapper from '../views/userPage/DriverListWrapper';
import OrderStatstics from '../views/order/order.statstics';
import ShareStatstics from '../views/stat/share.statstics';
import PushDetail from '../views/settings/push.detail';
import AdminProfile from '../views/userProfile/admin.profile';
import SuburbStastics from '../views/stat/suburb.statstics';
import MessageWrapper from '../views/message/message.wrapper';
import WaitDriverList from '../views/pickPage/wait.driverList';
import WaitDepotList from '../views/pickPage/wait.depotList';
import { CategoryList } from '../views/category/category.list';
import CategoryAdd from '../views/category/category.add';
import ContentList from '../views/content/content.list';
import MusicAdd from '../views/content/music.add';
import ContentStatPage from '../views/stat/content.stat';
import ContentCategoryList from '../views/content.category/content.category.list';
import { VideoList } from '../views/video/video.list';
import { VideoAdd } from '../views/video/video.add';
import { VideoAfter } from '../views/video/video.after';
import { ChapterList } from '../views/chapter/chapter.list';
import { ChapterAdd } from '../views/chapter/chapter.add';
import { CourseList } from '../views/course/course.list';
import { CourseAdd } from '../views/course/course.add';
import { OrderList } from '../views/order/order.list';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const DefaultDashBoard = Loadable(lazy(() => import('views/dashboard')));
const UserList = Loadable(lazy(() => import('views/userPage/UserListWrapper')));

const RouteDetail = Loadable(lazy(() => import('views/pick/RouteDetail')));
const PickList = Loadable(lazy(() => import('views/pickPage/PickPageWrapper')));
const UserProfile = Loadable(lazy(() => import('views/userProfile')));
const RoutingList = Loadable(lazy(() => import('views/pick-routing-pages/RoutingList')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dash-board',
            element: <DefaultDashBoard />
        },
        {
            path: '/user/default',
            element: <UserList />
        },
        {
            path: '/user/default/:id',
            element: <UserProfile />
        },
        { path: '/category/list', element: <CategoryList /> },
        { path: '/category/add/:idx', element: <CategoryAdd /> },
        { path: '/video/list', element: <VideoList /> },
        { path: '/video/add/:idx', element: <VideoAdd /> },
        { path: '/video/modify/:idx', element: <VideoAfter /> },
        { path: '/chapter/list', element: <ChapterList /> },
        { path: '/chapter/add/:idx', element: <ChapterAdd /> },
        { path: '/course/list', element: <CourseList /> },
        { path: '/course/add/:idx', element: <CourseAdd /> },
        { path: '/order/list', element: <OrderList /> },
        // { path: '/chapter/modify/:idx', element: <VideoAfter /> },
        { path: '/stat/content', element: <ContentStatPage /> },
        { path: '/messaging/setting', element: <MessageWrapper /> }
    ]
};

export default MainRoutes;
