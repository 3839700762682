import axios, { AxiosError, AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import { useContext, useEffect, useMemo } from 'react';
import { LOGOUT } from '../store/actions';
import useAuth from './useAuth';
import AuthContext from '../contexts/JWTContext';
import { useDispatch } from 'react-redux';

// const cookies = new Cookies();
// const baseUrl = process.env.REACT_APP_BASE_URL;

const useAxiosServices = (func: any = false) => {
    const cookies = new Cookies();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const context = useContext(AuthContext);
    const dispatch = useDispatch();

    const axiosApiRefreshToken = axios.create({
        baseURL: baseUrl,
        withCredentials: true
    });
    const axiosServicesConfig = axios.create({
        baseURL: baseUrl,
        withCredentials: true
    });
    useEffect(() => {}, []);

    axiosServicesConfig.interceptors.request.use(
        async (config) => {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                const requestHeader = config.headers;
                return {
                    ...config,
                    headers: {
                        ...requestHeader,
                        Authorization: `Bearer ${accessToken}`
                    }
                };
            }
            return config;
        },
        (error) => {
            console.log('Error: MAIN API Request', error);
            return Promise.reject(error);
        }
    );

    axiosApiRefreshToken.interceptors.request.use(
        async (config) => {
            const requestHeader = config.headers;
            return config;
        },
        (error) => {
            console.log('Error: Refresh Request', error);
            return Promise.reject(error);
        }
    );

    axiosApiRefreshToken.interceptors.response.use(
        async (config) => {
            const requestHeader = config.headers;
            return config;
        },
        (error) => {
            console.log('Error: Refresh Response', error);
            localStorage.removeItem('accessToken');
            dispatch({ type: LOGOUT });
            // navigate('/login', { replace: true });
            return Promise.reject(error);
        }
    );

    axiosServicesConfig.interceptors.response.use(
        (response) => {
            const originalRequest = response.config;
            if (originalRequest.url === '/users/login/admin') {
                const { accessToken } = response.data?.data;
                if (accessToken) {
                    localStorage.setItem('accessToken', accessToken);
                }
            }
            return response;
        },

        async (error: AxiosError) => {
            const originalRequest = error.config;
            if (error.response?.status === 401 && originalRequest.url === '/users/refresh') {
                // if (!context) throw new Error('context must be use inside provider');
                console.log('ONE2');
                return Promise.reject(error);
            }
            if (error.response?.status === 401) {
                try {
                    const res: AxiosResponse<{ accessToken: string }> = await axiosApiRefreshToken.get('/users/refresh');
                    const { accessToken } = res.data;
                    if (accessToken) {
                        localStorage.setItem('accessToken', accessToken);
                        return await axiosServicesConfig(originalRequest);
                    }
                    // navigate('/login', { replace: true });
                } catch (e) {
                    console.log('Error: MAIN API RESPONSE IN 401', e);
                    localStorage.removeItem('accessToken');
                    context?.logout();
                    // navigate('/login', { replace: true });
                    // dispatch({ type: LOGOUT });
                    return Promise.reject(error);
                }
            }
            console.log('Error: MAIN API RESPONSE IN MAIN', error);
            alert(`${error}`);
            return Promise.reject(error);
        }
    );
    const axiosService = useMemo(() => axiosServicesConfig, []);
    const serviceToken = useMemo(() => {
        const token = localStorage.getItem('accessToken');
        return token;
    }, []);
    return { axiosService, serviceToken };
};

export default useAxiosServices;
