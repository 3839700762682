import React from 'react';
import Editor from 'lch-editor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { CustomUploadAdapterPlugin } from './image.upload.plugin';
import './editor.css';

interface IHtmlEditor {
    text: string;
    setText: (text: string) => void;
    useImagePlugin?: boolean;
}

export const HtmlEditor = ({ text, setText, useImagePlugin }: IHtmlEditor) => {
    const handlePluginAdd = () => {
        const plugins = [];
        if (useImagePlugin) {
            plugins.push(CustomUploadAdapterPlugin);
        }
        return plugins;
    };

    const custom_config = {
        extraPlugins: handlePluginAdd()
    };
    return (
        <CKEditor
            editor={Editor}
            data={text ?? ''}
            config={custom_config}
            onInit={(editor) => {
                editor.editing.view.change((writer) => {
                    writer.setStyle('height', '500px', editor.editing.view.document.getRoot());
                });
            }}
            onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
                const addText = editor.getData();
                setText(addText);
            }}
            onBlur={(event, editor) => {
                console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
                console.log('Focus.', editor);
            }}
        />
    );
};
