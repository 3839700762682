import React, { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { userListProfile } from '_mockApis/user-profile/types';
import MaskedInput from 'react-text-mask';
import AnimateButton from 'ui-component/extended/AnimateButton';
import useAxiosServices from 'hooks/useAxios';
import AlertDialog from 'ui-component/dialog/AlertDialog';
import { toast } from 'react-toastify';
import { ToastSetting } from 'utils/common';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { PageWrapper } from 'layout/wrapper/page.wrapper';

const validationSchema = yup.object({
    name: yup.string().min(2, 'name should be of minimum 2 characters length').required('name is required'),
    lank: yup.number().typeError('rank must be a number').positive('rank must be greater than zero').required('rank is required')
});

const CategoryAdd = () => {
    const theme = useTheme();
    const { axiosService } = useAxiosServices();
    const navigate = useNavigate();
    const [depotType, setDepotType] = useState<number>(0);
    const [profile, setProfile] = useState<userListProfile | null>(null);
    const formik = useFormik({
        initialValues: {
            name: '',
            lank: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            setAlertOpen(true);
        }
    });

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const handlerAddress = (add, lat, lot) => {
        formik.setFieldValue('userAddress', add);
        setOpen((p) => !p);
    };

    useEffect(() => {}, []);

    const [alertOpen, setAlertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const [mainImage, setMainImage] = useState(null);
    const [logoImage, setLogoImage] = useState(null);

    const imageHandler = (file: any) => {
        setMainImage(file[0]);
    };
    const imageHandler2 = (file: any) => {
        setLogoImage(file[0]);
    };
    const onCancel = () => {
        setMainImage(null);
    };
    const onCancel2 = () => {
        setLogoImage(null);
    };

    const insertFetch = async () => {
        const { name, lank } = formik.values;
        if (!lank) {
            return alert('Please input rank or rank must be greater than 0');
        }

        try {
            const data = modify
                ? { idx, name, lank, use }
                : {
                      name,
                      lank,
                      use
                  };

            const api = await axiosService.post(modify ? 'content/category/update' : 'content/category/add', data);

            const { status } = api;
            if (status) {
                if (modify) {
                    toast.info('수정되었습니다.', { ...ToastSetting.default });
                    getData();
                } else {
                    toast.info('추가되었습니다.', { ...ToastSetting.default });
                    navigate('/category/list');
                }
            } else {
                toast.info('Error', { ...ToastSetting.default });
            }
            return;
        } catch (e) {
            toast.info('Error', { ...ToastSetting.default });
        } finally {
            setAlertOpen(false);
        }
    };

    const handleProfile = (data) => {
        setProfile({ ...data });
    };
    const [use, setUse] = useState(true);

    const { idx } = useParams();

    useEffect(() => {
        if (idx !== 'new') {
            getData();
        }
    }, [idx]);

    const getData = async () => {
        const api = await axiosService.post('/content/category/detail', { idx });
        const { result, data } = api.data;
        if (result) {
            await formik.setFieldValue('name', data.name);
            await formik.setFieldValue('lank', data.lank);
            setUse(data.use === 1);
            setImageURL(data.image);
            setModify(true);
        }
    };

    const deleteData = async () => {
        const api = await axiosService.post('/content/category/delete', { idx });
        const { result, data } = api.data;
        if (result) {
            setDeleteOpen(false);
            navigate('/content/category/list');
        }
    };
    const [imageURL, setImageURL] = useState(null);
    const [modify, setModify] = useState(false);

    return (
        <>
            <PageWrapper title={`카테고리 ${modify ? '업데이트' : '추가'}`}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing} p={2}>
                        {/* <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}> */}
                        {/*    <Typography variant={'h4'}>MainImage</Typography> */}
                        {/*    <Typography variant={'h5'}>140 * 96 </Typography> */}
                        {/*    <ImageUploader onChange={imageHandler} imageURL={imageURL} onCancel={onCancel} width={140} height={96} /> */}
                        {/* </Grid> */}
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="name"
                                fullWidth
                                label="Category Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="lank"
                                fullWidth
                                type={'number'}
                                label="Rank"
                                value={formik.values.lank}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.lank && Boolean(formik.errors.lank)}
                                helperText={formik.touched.lank && formik.errors.lank}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={use} onChange={() => setUse((p) => !p)} />} label="Use" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button variant="contained" type={'submit'}>
                                        {modify ? 'Update' : 'Add'} Category
                                    </Button>
                                </AnimateButton>
                                {modify && (
                                    <AnimateButton>
                                        <Button
                                            onClick={() => setDeleteOpen(true)}
                                            style={{ marginLeft: '20px' }}
                                            color={'error'}
                                            variant="contained"
                                            type={'button'}
                                        >
                                            DELETE
                                        </Button>
                                    </AnimateButton>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </PageWrapper>

            <AlertDialog
                title={modify ? 'Update Category' : 'Create Category'}
                content={modify ? 'Do you want to update Category?' : `Do you want to create Category?`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => insertFetch()}
            />
            <AlertDialog
                title={'Delete Category'}
                content={'Do you want to Delete Category?'}
                open={deleteOpen}
                handleClose={setDeleteOpen}
                handleSuccess={() => deleteData()}
            />
        </>
    );
};

export default CategoryAdd;
