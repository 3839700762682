interface IImageUploadPlugin {
    url: string;
    loader: any;
}

export class ImageUploadPlugin {
    private readonly loader: any;

    private readonly url: string;

    private xhr: any;

    constructor(props: IImageUploadPlugin) {
        console.log('props', props);
        // CKEditor 5's FileLoader instance.
        this.loader = props.loader;
        // URL where to send files.
        this.url = props.url;
    }

    // Starts the upload process.
    upload() {
        return new Promise((resolve, reject) => {
            this.initRequest();
            this.initListeners(resolve, reject);
            this.sendRequest();
        });
    }

    // Aborts the upload process.
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    // Example implementation using XMLHttpRequest.
    initRequest() {
        // eslint-disable-next-line no-multi-assign
        const xhr = (this.xhr = new XMLHttpRequest());
        xhr.open('POST', this.url, true);
        xhr.responseType = 'json';
    }

    // Initializes XMLHttpRequest listeners.
    initListeners(resolve, reject) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`;
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.addEventListener('error', (e) => {
            console.log(e);
            console.log('RESPONE ERROR', e);
            reject(genericErrorText);
        });
        xhr.addEventListener('abort', () => reject());
        xhr.addEventListener('load', () => {
            const response = xhr.response;
            console.log('RESPONE', response);
            const { uri, status, result } = response;
            if (uri) {
                resolve({
                    default: uri
                });
            } else if (!result || !uri) {
                return reject(genericErrorText);
            }
            // const test = JSON.stringify(response)
            // const api = JSON.parse(test);
            // const image = api.data
            // const result = api.ok;
            // const path = api.path
            // const url = path.replace('/files/','')
            // const imageUrl = 'https://media.ssuva.com/assets/'+url

            // console.log(imageUrl)

            // if ( !result || !path ) {
            //     return reject( genericErrorText );
            // }
            //
            // // If the upload is successful, resolve the upload promise with an object containing
            // // at least the "default" URL, pointing to the image on the server.
        });

        if (xhr.upload) {
            xhr.upload.addEventListener('progress', (evt) => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    // Prepares the data and sends the request.
    sendRequest() {
        const data = new FormData();
        this.loader.file
            .then((result) => {
                data.append('file', result);
                this.xhr.send(data);
            })
            .catch((e) => console.log(e));
    }
}

export function CustomUploadAdapterPlugin(editor) {
    console.log('ei', editor);
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
        new ImageUploadPlugin({ loader, url: `${process.env.REACT_APP_IMAGE_UPLOAD}` });
}
