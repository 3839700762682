import * as React from 'react';
import eme from 'videojs-contrib-eme';
import videojs, { VideoJsPlayer } from 'video.js';

// Styles
import 'video.js/dist/video-js.css';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import PlayerOptions = videojs.PlayerOptions;

videojs.registerPlugin('eme', eme);
interface IVideoPlayerProps {
    options: videojs.PlayerOptions;
    source: videojs.Tech.SourceObject;
    onReady?: (player: VideoJsPlayer) => void;
}

interface IProductVideo {
    source: videojs.Tech.SourceObject;
    handleUpdate: (duration) => void;
    playerControlRef?: MutableRefObject<VideoJsPlayer>;
}

const initialOptions: videojs.PlayerOptions = {
    controls: true,
    fluid: true,
    aspectRatio: '16:9',
    controlBar: {
        volumePanel: {
            inline: false
        }
    }
};

// const VideoPlayer: React.FC<IVideoPlayerProps> = ({ options, onReady, source }) => {
//     const videoNode = useRef(null);
//     const [player, setPlayer] = useState<videojs.Player | null>(null);
//     useEffect(() => {
//         if (videoNode.current) {
//             const playerNode = videojs(videoNode.current, {
//                 ...initialOptions,
//                 ...options
//             });
//             // @ts-ignore
//             setPlayer(playerNode);
//             onReady(playerNode);
//             return () => {
//                 if (player) {
//                     player.dispose();
//                 }
//             };
//         }
//     }, []);
//
//     // eslint-disable-next-line jsx-a11y/media-has-caption
//     return (
//         <div data-vjs-player>
//             {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
//             <video ref={videoNode} className="video-js" />
//         </div>
//     );
// };

export class VideoPlayer extends React.Component<IVideoPlayerProps> {
    private player: videojs.Player | any;

    private videoNode: any;

    constructor(props) {
        super(props);
        videojs.registerPlugin('eme', eme);
    }

    componentDidMount() {
        const { source, options, onReady } = this.props;
        // instantiate video.js
        // eslint-disable-next-line react/destructuring-assignment
        this.player = videojs(this.videoNode, { ...options, ...initialOptions });
        this.player.eme();
        console.log('source', source);
        this.player.src(source);
        onReady(this.player);
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div data-vjs-player>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption,no-return-assign */}
                <video ref={(node) => (this.videoNode = node)} className="video-js" />
            </div>
        );
    }
}

const AdminVideo = ({ source, handleUpdate, playerControlRef }: IProductVideo) => {
    const playerRef = useRef<VideoJsPlayer | null>(null);
    useEffect(() => {
        console.log('playerRef.current 2', playerRef.current);
        playerRef.current?.src(source);
    }, [source]);

    const handlePlayerReady = (player: VideoJsPlayer) => {
        playerRef.current = player;
        if (playerControlRef) {
            playerControlRef.current = player;
        }
        console.log('player', player);

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('loadedmetadata', () => {
            const duration = player?.duration();
            console.log('duration', duration);
            handleUpdate(duration);
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };
    return <VideoPlayer options={{}} onReady={handlePlayerReady} source={source} />;
};

export default AdminVideo;
