import React, { CSSProperties, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { getUuid } from '../../utils/get.uuid';
import { Button, ClickAwayListener, DialogActions, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { IconTrash, IconSettings, IconFileText, IconEdit } from '@tabler/icons';
import { Theme, useTheme } from '@mui/material/styles';
import { Box, SxProps } from '@mui/system';
import { ISideContentType } from '../../types/video.types';

interface IChapterSideRow {
    item: ISideContentType;
    index: number;
    handleModifyContentList: (content: ISideContentType) => void;
    handleFilterContentList: (content: ISideContentType) => void;
    handleContentSelect: (content: ISideContentType) => void;
}
const getDragWrapper = (isDragging: boolean, theme: Theme): CSSProperties | undefined => ({
    backgroundColor: isDragging ? theme.palette.dark.main : 'transparent',
    userSelect: 'none'
});

export const ChapterSideRow = ({ item, index, handleContentSelect, handleModifyContentList, handleFilterContentList }: IChapterSideRow) => {
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const styles: SxProps = {
        position: 'absolute',
        top: 28,
        right: 0,
        left: 0,
        zIndex: 1,
        p: 1,
        bgcolor: 'background.paper'
    };
    return (
        <>
            <Draggable draggableId={`side-${item.id}`} key={getUuid()} index={index} isDragDisabled={false}>
                {(provideds, snapshots) => (
                    <Grid
                        container
                        ref={provideds.innerRef}
                        {...provideds.draggableProps}
                        {...provideds.dragHandleProps}
                        justifyContent={'center'}
                        alignItems={'center'}
                        minHeight={'60px'}
                        sx={{ ...getDragWrapper(snapshots.isDragging, theme), position: 'relative', paddingX: '10px' }}
                    >
                        <Grid xs={7} item sx={{ background: 'transparent' }}>
                            <Typography sx={{ background: 'transparent' }}>{item.title}</Typography>
                        </Grid>
                        <Grid xs={2} item sx={{ background: 'transparent' }}>
                            <Typography sx={{ background: 'transparent' }}>{item.time}</Typography>
                        </Grid>
                        <Grid xs={3} item sx={{ position: 'relative', background: 'transparent' }}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <Box sx={{ position: 'relative' }}>
                                    <Button type="button" onClick={handleClick}>
                                        <IconSettings />
                                    </Button>
                                    {open ? (
                                        <Box sx={styles}>
                                            <Tooltip placement="top" title="컨텐츠 입력">
                                                <IconButton
                                                    onClick={() => {
                                                        handleContentSelect(item);
                                                        setOpen(false);
                                                    }}
                                                    color="primary"
                                                    sx={{
                                                        color: theme.palette.info.dark,
                                                        borderColor: theme.palette.info.main,
                                                        '&:hover ': { background: theme.palette.info.light }
                                                    }}
                                                    size="large"
                                                >
                                                    <IconFileText fontSize={'1.1rem'} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title="수정">
                                                <IconButton
                                                    onClick={() => {
                                                        handleModifyContentList(item);
                                                        setOpen(false);
                                                    }}
                                                    color="secondary"
                                                    sx={{
                                                        color: theme.palette.secondary.dark,
                                                        borderColor: theme.palette.secondary.main,
                                                        '&:hover ': { background: theme.palette.secondary.light }
                                                    }}
                                                    size="large"
                                                >
                                                    <IconEdit fontSize={'1.1rem'} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title="컨텐츠 제거">
                                                <IconButton
                                                    onClick={() => {
                                                        handleFilterContentList(item);
                                                        setOpen(false);
                                                    }}
                                                    color="primary"
                                                    sx={{
                                                        color: theme.palette.orange.dark,
                                                        borderColor: theme.palette.orange.main,
                                                        '&:hover ': { background: theme.palette.orange.light }
                                                    }}
                                                    size="large"
                                                >
                                                    <IconTrash fontSize={'1.1rem'} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    ) : null}
                                </Box>
                            </ClickAwayListener>
                        </Grid>
                    </Grid>
                )}
            </Draggable>
        </>
    );
};
