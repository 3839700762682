import React, { CSSProperties } from 'react';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { IconPlus, IconTrash } from '@tabler/icons';
import { getUuid } from '../../utils/get.uuid';
import { gridSpacing } from '../../store/constant';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ISideContentType, VodListTypes } from '../../types/video.types';
import { ChapterSideRow } from './chapter.side.row';

interface IChapterSide {
    contentList: ISideContentType[];
    handleContentList: (any) => void;
    handleReadyContentList: () => void;
    video: VodListTypes | null;
    handleModifyContentList: (content: ISideContentType) => void;
    handleFilterContentList: (content: ISideContentType) => void;
    handleContentSelect: (content: ISideContentType) => void;
}
const getDragWrapper = (isDragging: boolean, theme: Theme): CSSProperties | undefined => ({
    backgroundColor: isDragging ? 'transparent' : 'transparent',
    userSelect: 'none'
});

export const ChapterSide = ({
    video,
    handleFilterContentList,
    handleModifyContentList,
    contentList,
    handleContentList,
    handleReadyContentList,
    handleContentSelect
}: IChapterSide) => {
    const theme = useTheme();
    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId, type } = result;

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        if (!result.destination) {
            return;
        }

        const items = reorder(contentList, result.source.index, result.destination.index);

        // @ts-ignore
        handleContentList([...items]);
        const orderData = items.map((v: any, index) => ({ order: index + 1, idx: v.idx as any }));
        // setData([...orderData]);
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid xs={12} mb={3}>
                    <Grid xs={2}>
                        <Tooltip placement="top" title="컨텐츠 추가">
                            <Button
                                disabled={!video}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => handleReadyContentList()}
                            >
                                <IconPlus />
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid xs={12} mb={3}>
                    <Grid xs={12}>
                        <Typography>{'컨텐츠 리스트'}</Typography>
                    </Grid>
                </Grid>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <Grid mx={1} container xs={12} spacing={gridSpacing} {...provided.droppableProps} ref={provided.innerRef}>
                            {contentList.map((v, index) => (
                                <ChapterSideRow
                                    item={v}
                                    handleFilterContentList={handleFilterContentList}
                                    index={index}
                                    handleModifyContentList={handleModifyContentList}
                                    handleContentSelect={handleContentSelect}
                                />
                            ))}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};
