import React, { useEffect, useState } from 'react';
import { PageWrapper } from 'layout/wrapper/page.wrapper';
import { useNavigate } from 'react-router-dom';
import { CategoryListType } from 'types/content.types';
import useAxios from 'hooks/useAxios';
import { addUuidList } from 'utils/get.uuid';
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import CategoryRow from './category.row';
import { LOADING_END, LOADING_START } from 'store/actions';
import { useDispatch } from 'react-redux';

export const CategoryList = () => {
    const navigation = useNavigate();
    const { axiosService } = useAxios();
    const [data, setData] = React.useState<CategoryListType[]>([]);
    const [mainList, setMainList] = useState<string[]>([]);
    const dispatch = useDispatch();
    const getData = async () => {
        const response = await axiosService.post('/content/category/list');
        const { result, list } = response.data;
        if (result) {
            const addList = addUuidList(list);
            setData([...addList]);
            setMainList([...addList.map((v) => `${v.uuid}`)]);
        }
    };
    useEffect(() => {
        getData();
    }, []);
    const sortListSave = async () => {
        const orderData = data.map((v: any, index) => ({ order: index + 1, idx: v.idx as any }));
        try {
            dispatch({ type: LOADING_START });
            await axiosService.post('content/category/order/update', { data: orderData });
            await getData();
        } catch (e) {
        } finally {
            dispatch({ type: LOADING_END });
        }
    };
    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId, type } = result;
        console.log('result', result);

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        if (!result.destination) {
            return;
        }

        const items = reorder(data, result.source.index, result.destination.index);

        // @ts-ignore
        setData([...items]);
        const orderData = items.map((v: any, index) => ({ order: index + 1, idx: v.idx as any }));
        console.log('ORDER', orderData);
        // setData([...orderData]);
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    return (
        <PageWrapper title={'Course 카테고리'} subject={'Course 생성시 사용될 카테고리 리스트입니다.'}>
            <div>
                <Grid my={2} container flexDirection={'row'} width={'100%'} justifyContent={'flex-end'}>
                    <Grid item alignItems={'center'} justifyContent={'center'}>
                        <Button variant={'outlined'} onClick={() => sortListSave()}>
                            정렬 순서 저장
                        </Button>
                    </Grid>
                    <Grid item ml={2}>
                        <Button variant={'outlined'} onClick={() => navigation('/content/category/add/new')}>
                            {' '}
                            신규 카테고리 추가
                        </Button>
                    </Grid>
                </Grid>

                <TableContainer>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                // <Table {...provided.droppableProps} ref={provided.innerRef} sx={getDropWrapper(snapshot.isDraggingOver, theme)}>
                                //     <TableHead>
                                //         <TableRow>
                                //             <TableCell sx={{ pl: 3 }}>#</TableCell>
                                //             <TableCell>name</TableCell>
                                //             <TableCell>image</TableCell>
                                //             <TableCell>rank</TableCell>
                                //             <TableCell>use</TableCell>
                                //             <TableCell align="center" sx={{ pr: 3 }}>
                                //                 More
                                //             </TableCell>
                                //         </TableRow>
                                //     </TableHead>
                                //     <TableBody>
                                //         {data.map((row, index: number) => (
                                //             // const story = data.filter((item) => `category_${item.idx}` === idx)[0];
                                //             // console.log('STORY,', story);
                                //             <CategoryRow row={row} index={index} />
                                //         ))}
                                //         {provided.placeholder}
                                //     </TableBody>
                                // </Table>
                                <Table {...provided.droppableProps} ref={provided.innerRef}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>이름</TableCell>
                                            <TableCell>정렬순위</TableCell>
                                            <TableCell>등록컨텐츠</TableCell>
                                            <TableCell>사용여부</TableCell>
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                수정
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((item, index) => (
                                            <CategoryRow row={item} index={index} />
                                        ))}
                                    </TableBody>
                                    {provided.placeholder}
                                </Table>
                            )}
                        </Droppable>
                    </DragDropContext>
                </TableContainer>
            </div>
        </PageWrapper>
    );
};
