import React, { CSSProperties, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { getUuid } from '../../utils/get.uuid';
import { Button, ClickAwayListener, DialogActions, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { IconTrash, IconSettings, IconFileText, IconEdit } from '@tabler/icons';
import { Theme, useTheme } from '@mui/material/styles';
import { Box, SxProps } from '@mui/system';
import { IChapterContentType, ISideContentType } from '../../types/video.types';

interface IChapterSideRow {
    item: IChapterContentType;
    index: number;
    handleFilterContentList: (content: IChapterContentType) => void;
}
const getDragWrapper = (isDragging: boolean, theme: Theme): CSSProperties | undefined => ({
    backgroundColor: isDragging ? theme.palette.dark.main : 'transparent',
    userSelect: 'none'
});

export const CourseSideRow = ({ item, index, handleFilterContentList }: IChapterSideRow) => {
    const theme = useTheme();

    const styles: SxProps = {
        position: 'absolute',
        top: 28,
        right: 0,
        left: 0,
        zIndex: 1,
        p: 1,
        bgcolor: 'background.paper'
    };
    return (
        <>
            <Draggable draggableId={`side-${item.id}`} key={getUuid()} index={index} isDragDisabled={false}>
                {(provideds, snapshots) => (
                    <Grid
                        container
                        ref={provideds.innerRef}
                        {...provideds.draggableProps}
                        {...provideds.dragHandleProps}
                        justifyContent={'center'}
                        alignItems={'center'}
                        minHeight={'60px'}
                        sx={{ ...getDragWrapper(snapshots.isDragging, theme), position: 'relative', paddingX: '10px' }}
                    >
                        <Grid xs={5} item sx={{ background: 'transparent' }}>
                            <Typography sx={{ background: 'transparent' }}>{item.title}</Typography>
                        </Grid>
                        <Grid xs={4} item sx={{ background: 'transparent', overflow: 'hidden' }}>
                            <Typography sx={{ background: 'transparent', overflow: 'hidden' }}>{`${item.video?.title}`}</Typography>
                        </Grid>
                        <Grid xs={3} item sx={{ position: 'relative', background: 'transparent' }}>
                            <Box sx={{ position: 'relative' }}>
                                <Tooltip placement="top" title="챕터 제거">
                                    <IconButton
                                        onClick={() => {
                                            handleFilterContentList(item);
                                        }}
                                        color="primary"
                                        sx={{
                                            color: theme.palette.orange.dark,
                                            borderColor: theme.palette.orange.main,
                                            '&:hover ': { background: theme.palette.orange.light }
                                        }}
                                        size="large"
                                    >
                                        <IconTrash fontSize={'1.1rem'} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Draggable>
        </>
    );
};
