import { IconTruckDelivery } from '@tabler/icons';

const icons = {
    IconTruckDelivery
};

const orderRouting = {
    id: 'order-menu',
    type: 'group',
    children: [
        {
            id: 'Order list',
            title: '주문',
            type: 'item',
            icon: icons.IconTruckDelivery,
            url: '/order/list'
        }
    ]
};

export default orderRouting;
