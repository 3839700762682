import React, { useState } from 'react';
import { useTheme } from '@mui/system';
import useAxios from '../../hooks/useAxios';
import {
    Box,
    Button,
    Drawer,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { gridSpacing } from '../../store/constant';
import { IChapterContentType, VodListTypes } from '../../types/video.types';
import { addUuidList, getUuid } from '../../utils/get.uuid';
import { SelectChangeEvent } from '@mui/material/Select';
import { ListSelectSort, VodMenuProps, VodSortOptionSelectConst, VodStatusSelectConst, VodUseSelectConst } from 'views/video/vod.helper';
import { IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ChapterDrawerRow from './chapter.drawer.row';

interface IVideoDrawer {
    contentList: IChapterContentType[];
    open: boolean;
    handleDrawerOpen: () => void;
    handleAddVideo: (rows: IChapterContentType) => void;
}

const ChapterDrawer = ({ open, contentList, handleDrawerOpen, handleAddVideo }: IVideoDrawer) => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const [data, setData] = React.useState<IChapterContentType[]>([]);
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        sort: 'ASC' | 'DESC';
        search: string;
        use: '1' | '0' | 'none';
        course: any[];
        sortOption: 'createdAt' | 'updatedAt';
    }>({
        skip: 0,
        take: 10,
        sort: 'ASC',
        search: '',
        use: '0',
        course: [],
        sortOption: 'createdAt'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);

    const handlePageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };

    const handlePageClose = () => {
        setAnchorEl(null);
    };

    const handlePageRowClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeTake = (value) => {
        setListParam((p) => ({ ...p, take: value }));
        setAnchorEl(null);
    };

    const getDataApi = async () => {
        const api = await axiosService.post('/chapter/list/admin', { ...listParam });
        const { list, allPage, count } = api.data;
        if (list) {
            const addList = addUuidList(list);
            setData([...addList]);
            setPaging((p) => ({
                ...p,
                allPage,
                count
            }));
        }
    };

    React.useEffect(() => {
        getDataApi();
    }, [listParam]);
    const handleChangeSortOptionSelect = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setListParam((p) => ({ ...p, sortOption: value }));
    };
    const handleChangeSortSelect = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setListParam((p) => ({ ...p, sort: value }));
    };
    const handleChangeStatusSelect = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setListParam((p) => ({ ...p, status: value }));
    };
    const handleChangeStatusUse = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setListParam((p) => ({ ...p, use: value }));
    };
    const [search, setSearch] = useState('');
    const handleSelectSearch = () => {
        setListParam((p) => ({ ...p, search }));
    };
    return (
        <Drawer
            sx={{
                ml: open ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 900 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 900 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={open}
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
        >
            <div>
                <Grid my={2} container flexDirection={'row'} width={'100%'} justifyContent={'flex-end'} alignItems={'flex-end'}>
                    <FormControl sx={{ m: 1, width: 150 }}>
                        <Select
                            labelId="listSelectOption"
                            id="listSelectOption"
                            value={listParam.sortOption}
                            onChange={handleChangeSortOptionSelect}
                            MenuProps={VodMenuProps}
                        >
                            {VodSortOptionSelectConst.map((value, i) => (
                                <MenuItem key={`${getUuid()}`} value={value.value}>
                                    <ListItemText primary={value.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 100 }}>
                        <Select
                            labelId="listSelectSort"
                            id="listSelectSort"
                            value={listParam.sort}
                            onChange={handleChangeSortSelect}
                            MenuProps={VodMenuProps}
                        >
                            {ListSelectSort.map((value, i) => (
                                <MenuItem key={`${getUuid()}`} value={value.value}>
                                    <ListItemText primary={value.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 100 }}>
                        <FormLabel>사용여부</FormLabel>
                        <Select
                            labelId="listSelectUse"
                            id="listSelectUse"
                            value={listParam.use}
                            onChange={handleChangeStatusUse}
                            MenuProps={VodMenuProps}
                        >
                            {VodUseSelectConst.map((value, i) => (
                                <MenuItem key={`${getUuid()}`} value={value.value}>
                                    <ListItemText primary={value.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid my={2} pr={1} container flexDirection={'row'} width={'100%'} justifyContent={'flex-end'} alignItems={'flex-end'}>
                    <OutlinedInput
                        sx={{ height: '50px', width: '250px' }}
                        id="input-search"
                        placeholder="검색"
                        value={search}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSelectSearch();
                            }
                        }}
                        onChange={(e) => setSearch(e.currentTarget.value)}
                        startAdornment={
                            <InputAdornment position="start" onClick={() => handleSelectSearch()} sx={{ cursor: 'pointer' }}>
                                <IconSearch stroke={0.5} size="0.5rem" />
                            </InputAdornment>
                        }
                        size="small"
                    />
                </Grid>

                <TableContainer sx={{ minHeight: '40vh' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>이름</TableCell>
                                <TableCell>비디오</TableCell>
                                <TableCell>컨텐츠 수</TableCell>
                                <TableCell>등록일</TableCell>
                                <TableCell align="center" sx={{ pr: 3 }}>
                                    불러오기
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, index) => (
                                <ChapterDrawerRow
                                    current={!!contentList.find((v) => item.id === v.id)}
                                    row={item}
                                    index={index}
                                    key={item.key}
                                    handleAddRow={handleAddVideo}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={handlePageChange} />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handlePageRowClick}
                            >
                                {`${listParam.take} Rows`}
                            </Button>
                            <Menu
                                id="menu-video-list"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handlePageClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                {new Array(10).fill(10).map((v, i) => (
                                    <MenuItem onClick={() => handleChangeTake(v + i * v)}> {v + i * v}+</MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Drawer>
    );
};
export default ChapterDrawer;
