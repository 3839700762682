import { IconSubtask } from '@tabler/icons';

const icons = {
    IconSubtask
};

const ChapterRoute = {
    id: 'chapter-route',
    type: 'group',
    children: [
        {
            id: 'chapter',
            title: '챕터',
            type: 'collapse',
            icon: icons.IconSubtask,
            children: [
                {
                    id: 'chapter-list',
                    title: '챕터 리스트',
                    type: 'item',
                    url: '/chapter/list'
                },
                {
                    id: 'chapter-add',
                    title: '챕터 추가',
                    type: 'item',
                    url: '/chapter/add/new'
                }
            ]
        }
    ]
};

export default ChapterRoute;
