export const ConvertSecond = (s) => {
    if (!s) {
        return `00:00`;
    }
    if (isNaN(s)) {
        return `00:00`;
    }
    const seconds = Math.round(s);

    const hour =
        parseInt(String(seconds / 3600), 10) < 10 ? `0${parseInt(String(seconds / 3600), 10)}` : parseInt(String(seconds / 3600), 10);
    const min =
        parseInt(String((seconds % 3600) / 60), 10) < 10
            ? `0${parseInt(String((seconds % 3600) / 60), 10)}`
            : parseInt(String((seconds % 3600) / 60), 10);
    const sec = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60;

    return `${hour}:${min}:${sec}`;
};
